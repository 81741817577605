<template>
  <div v-if="category" class="category-title">
    <h2
      v-if="!category.metaData.category_info.HIDE_TITLE"
      class="primary--text pa-3 pt-0 pt-sm-3"
    >
      {{ title }}
    </h2>
    <div
      v-if="!category.metaData.category_info.HIDE_TITLE && subtitle"
      v-html="subtitle"
      class="primary--text px-3"
    ></div>
  </div>
</template>
<style scoped lang="scss"></style>
<script>
import get from "lodash/get";

export default {
  name: "ProfileTitle",
  props: {
    category: { type: Object, required: true }
  },
  computed: {
    title() {
      let c = this.selectedCategory ? this.selectedCategory : this.category;
      return get(c, "metaData.category_info.TITLE", c.name);
    },
    subtitle() {
      return get(
        this.category,
        "metaData.category_info.DESCRIPTION",
        this.category.description
      );
    }
  }
};
</script>
