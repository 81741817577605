<template>
  <div class="profile-section reset-password thank-you-page">
    <v-container>
      <Breadcrumb :items="vBreadcrumb" />
      <CategoryTitle v-if="category" :category="category" />
      <div class="elevation-0">
        <div class="d-flex align-left flex-column mb-3">
          <ProfileTitle :category="category" />
        </div>
        <v-form
          v-on:submit.prevent
          ref="form"
          v-model="valid"
          :lazy-validation="lazy"
          class="mt-10 mb-5"
        >
          <v-row justify="start">
            <v-col cols="12" md="4">
              <v-text-field
                v-model="email"
                label="INDIRIZZO E-MAIL"
                required
                outlined
                :rules="emailRules"
                dense
                clearable
                @keyup.enter="handleResetPasswordRequest"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <ResponseMessage class="mb-5" :response="response" />
        <div class="mt-2 mb-8 register-wrapper">
          <!-- to="/register" -->
          <v-btn
            @click="handleResetPasswordRequest"
            class="reset-button"
            color="secondary"
            large
            depressed
            :loading="loading"
            :disabled="!valid"
            >{{ $t("login.submitResetPassword") }}</v-btn
          >
        </div>
      </div>
    </v-container>
  </div>
</template>
<style scoped lang="scss">
.v-input {
  .v-input__slot {
    margin-left: 0px;
  }
}
.response-messages .v-alert {
  &.v-alert--outlined {
    border: none;
  }
}
</style>
<script>
import RegistrationService from "@/service/eurospinCustomService";
import ResponseMessage from "@/components/ResponseMessage.vue";
import ProfileTitle from "@/components/profile/ProfileTitle";
import Breadcrumb from "@/components/navigation/Breadcrumb";
import CategoryTitle from "@/components/category/CategoryTitle";
import { requiredValue, isEmail } from "@/validator/validationRules";
import category from "~/mixins/category";

var vm = null;
export default {
  components: { ResponseMessage, ProfileTitle, Breadcrumb, CategoryTitle },
  name: "ResetPasswordRequest",
  mixins: [category],
  data() {
    return {
      email: "",
      valid: true,
      loading: false,
      lazy: true,
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      response: {}
    };
  },
  computed: {
    vBreadcrumb() {
      let breadCrumbs = [];
      breadCrumbs.push({
        to: { name: "Home" },
        text: "Homepage",
        exact: true
      });
      breadCrumbs.push({
        to: {
          name: "ResetPasswordRequest"
        },
        text: "Modifica Password",
        exact: true
      });
      return breadCrumbs;
    }
  },
  methods: {
    async handleResetPasswordRequest(e) {
      e.preventDefault();
      vm.response = {};
      if (vm.valid) {
        vm.loading = true;
        try {
          const res = await RegistrationService.getResetPasswordToken(vm.email);
          if (res.response) {
            vm.response = res.response;
          }
        } catch (err) {
          if (err.response) {
            vm.response = err.response.data.response;
          } else {
            vm.response = {
              errors: [{ error: "Email non trovata" }],
              infos: [],
              status: 0
            };
          }
        } finally {
          vm.loading = false;
        }
      }
    },
    resetPage() {
      this.email = "";
      this.response = {};
    }
  },
  created() {
    vm = this;
  }
};
</script>

<style></style>
